"use strict";

var ns = require('ns');
var $ = require('jquery');

require('../vendor/jquery.scrollto.min');

//var priceBrackets = pricePiet;

function getParameterByName(name, url) {
    if (!url) url = window.location.href;
    name = name.replace(/[\[\]]/g, "\\$&");
    var regex = new RegExp("[?&]" + name + "(=([^&#]*)|&|#|$)"),
        results = regex.exec(url);
    if (!results) return null;
    if (!results[2]) return '';
    return decodeURIComponent(results[2].replace(/\+/g, " "));
}

var Product = function(){

};

Product.prototype = {
    init:function(){
        this.colors = $('.color-selector').find('.color');
        this.sizeSelect = $('.size-selector').find('select');
        this.priceEl = $('.price');

        this.customSizeForm = $('.custom-size');
        this.errors = this.customSizeForm.find('.error');
        this.calculateBtn = $('#calculate-price');
        this.addToCartBtn = $('#add-to-cart');
        this.productType = this.customSizeForm.data('producttype') == 45 ? 'renf' : 'piet';
        this.size = 'standard';

        this.getAvailableColors();
        this.setupEvents();
    },

    setupEvents:function(){
        var _self = this;
        this.colors.on('click.dragar', function(e){
            _self.switchColors(e);
        });

        this.sizeSelect.on('change.dragar', function(e){
            _self.onSizeSelect(e);
        });

        this.calculateBtn.on('click.dragar', function(e){
            _self.checkCustomSizes(e)
        });

        this.addToCartBtn.on('click.dragar', function(e){
            _self.addToCart(e);
        });
    },

    getAvailableColors:function(){
        var _self = this;
        $.ajax({
            url:ns.basePath + '/modules/product.php',
            type:'POST',
            dataType:'JSON',
            data:{
                "getColors": true,
                "productType": _self.productType
            },
            success:function(data){
                _self.availableColors = data;

                if(getParameterByName('custom') !== null) {
                    _self.sizeSelect.val('custom').change();
                }

                var colorURI = getParameterByName('color');
                if(colorURI !== null) {
                    _self.colors.filter(':visible.' + colorURI).trigger('click');
                }
            }
        });
    },

    setPrice:function(area){
        var _self = this;
        $.ajax({
            url:ns.basePath + '/modules/product.php',
            type:'POST',
            dataType:'JSON',
            data:{
                "getPrice": true,
                "area": area,
                "productType": _self.productType
            },
            success:function(data){
                if(data.success !== true){
                    _self.errors.html(data.error_message).show();
                }

                _self.priceEl.html(data.price.toString().replace('.', ',') + '$');
            }
        });
    },

    onSizeSelect:function(e){
        var val = $(e.currentTarget).val();
        if(val != 'custom') {
            this.setPrice(null);
            this.size = 'standard';
            this.customSizeForm.slideUp();
        }
        else {
            this.customSizeForm.slideDown();
        }

        this.showAvailableColors(val == 'custom');
    },

    checkCustomSizes:function(e){
        var length = parseFloat($('#custom-length').val());
        var width = parseFloat($('#custom-width').val());

        if(!isNaN(width) && !isNaN(length)) {
            this.errors.hide();
            this.size = {
                length: length,
                width: width
            };
            this.area = length * width;
            this.setPrice(this.area);

            $(window).scrollTo($('.product-content'), 300);
        }
        else {
            this.errors.html('Veuillez entrer deux valeurs numériques. Utilisez la notation à points.').show();
        }
    },

    updatePicture:function(){
        var activeColor = this.colors.filter(':visible.active').data('color');

        var showcase = $('.product-images').filter(':visible').find('.showcase');
        var thumb = $('.product-images').filter(':visible').find('.thumb').filter('[data-color="' + activeColor + '"]');

        var oldActiveColor = showcase.attr('data-color');
        var oldShowcaseBg = showcase.css('background-image');
        var oldThumbBg = thumb.css('background-image');

        showcase.css('background-image', oldThumbBg).attr('data-color', activeColor);
        thumb.css('background-image', oldShowcaseBg).attr('data-color', oldActiveColor);
    },

    switchColors:function(e){
        // Change la classe active juste quand c'est nécessaire
        if(e) {
            e.preventDefault();
            var _colorEl = $(e.currentTarget);
            _colorEl.parent().find('.color').removeClass('active');
            _colorEl.addClass('active');
        }

        this.updatePicture();

        // Update le nom
        var colorName = this.colors.filter(':visible.active').data('product-color');
        $('[data-update-color]').html(colorName);
    },

    showAvailableColors:function(isCustomSize){
        this.colors.addClass('unavailable');
        var availableColors = this.availableColors[isCustomSize ? 'custom' : 'standard'];

        // Some colors are unavailable
        var _self = this;
        availableColors.forEach(function(_availableColor, i){
            _self.colors.filter('.' + _availableColor).removeClass('unavailable');
        });

        var colorsSelector = isCustomSize ? '[data-custom-colors]' : '[data-standard-colors]';
        $('.product-images').hide();
        $('.color-selector > div').hide();
        $(colorsSelector).show();

        _self.switchColors();
    },

    addToCart:function(e){
        e.preventDefault();

        var _self = this;
        var _imgUrl = $('.product-images div')
                            .filter(':visible[data-color="' + _self.colors.filter(':visible.active').data('color') + '"]')
                            .css('background-image');
        _imgUrl = _imgUrl.substring(5, _imgUrl.length - 2);

        var _product = {
            "img": _imgUrl,
            "name": $('.product-name').data('product-name'),
            "color": _self.colors.filter(':visible.active').data('product-color'),
            "size": _self.size,
            "type": _self.productType,
            "qty": 1
        };

        console.log(_product);
        //return;


        var priceDfd = $.ajax({
            url:ns.basePath + '/modules/product.php',
            type:'POST',
            dataType:'JSON',
            data:{
                getPrice: true,
                area: _self.area || null,
                productType: _self.productType
            },
            success:function(data){
                _product.price = data.price;
            }
        });

        priceDfd.done(function(){
            $.ajax({
                url:ns.basePath + '/modules/cart.php',
                type:'POST',
                dataType:'JSON',
                data:{
                    "addToCart": true,
                    "product": _product
                },
                success:function(data){
                    var url = window.location.href;
                    if (url.indexOf('?') > -1){
                       url += '&success=1'
                    }else{
                       url += '?success=1'
                    }
                    window.location.href = url;
                }
            });
        });
    }
}

module.exports = Product;
