"use strict";


require('./Cart');
var ns = require('ns');
var $ = require('jquery');
//var Menu = require('./Menu');
require('selectric');
require('../vendor/jquery.scrollto.min');
var Isotope = require('../../node_modules/isotope-layout/js/isotope.js');
var Product = require('./Product');

ns.docReady.then(function(){

	$('.specs')
		.find('.visible-content')
		.on('click.dragar', function(e){
			e.preventDefault();

			$(this).siblings('.hidden-content').fadeToggle(300);
		});

	$('.menu-btn').on('click.dragar', function(e){
		e.preventDefault();

		$('nav.offcanvas').toggleClass('opened');
	});

	$('.offcanvas').find('.menu-item-has-children > a').on('click', function(e){
		e.preventDefault();
		$(e.currentTarget).siblings('.sub-menu').slideToggle();
	});

	var product = new Product().init();

	if($('.cart-table').length > 0) {
		ns.Cart.init();
	}

	$('.scrolldown').on('click', function(e){
		e.preventDefault();

		$(window).scrollTo($('.product-row'), 300);
	})
});
