"use strict";

var ns = require('ns');
var $ = require('jquery');

(function(){
    var Cart = function(){

    };

    Cart.prototype = {
        init:function(){
            this.cartItems = $('.cart-item');

            this.setupEvents();
        },

        setupEvents:function(){
            var _self = this;

            _self.cartItems.find('.cart-item-remove').on('click.dragar', function(e){
                _self.removeFromCart(e);
            });

            _self.cartItems.find('.quantity-modifier').on('click.dragar', function(e){
                _self.updateQuantity(e);
            });
        },

        updateQuantity:function(e){
            e.preventDefault();

            var mod = parseFloat($(e.currentTarget).data('modifier'));
            var cartItem = $(e.currentTarget).parent().parent().parent();
            var cartItemId = cartItem.data('product-id');

            $.ajax({
                url:ns.basePath + '/modules/cart.php',
                type:'POST',
                dataType:'JSON',
                data:{
                    "updateQuantity": true,
                    "productID": cartItemId,
                    "modifier": mod
                },
                success:function(data){
                    window.location.reload();
                }
            });
            //console.log(mod);
        },

        removeFromCart:function(e){
            e.preventDefault();

            var cartItem = $(e.currentTarget).parent().parent();
            var cartItemId = cartItem.data('product-id');

            $.ajax({
                url:ns.basePath + '/modules/cart.php',
                type:'POST',
                dataType:'JSON',
                data:{
                    "removeFromCart": true,
                    "productID": cartItemId
                },
                success:function(data){
                    window.location.reload();
                }
            });
        }
    };

    ns.Cart = new Cart();
})();
